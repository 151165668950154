import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import Stories from "../pages/Stories";
import Anuncio from "../pages/Anuncio";
import Bio from "../pages/Bio";

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/stories" component={Stories} />
            <Route path="/bio" component={Bio} />
            <Route path="/anuncio" component={Anuncio} />
        </Switch>
    );
};

export default Routes;
